import React from 'react';

export default () => {

    const skills = [
        'Linux',
        'Docker',
        'Kubernetes',
        'Prometheus',
        'OpenShift',
        'Ansible',
        'Git',
        'AWS',
        'Azure',
        'Heroku',
        'Javascript',
        'ReactJS',
        'NodeJS',
        'ExpressJS',
        'HTML5',
        'CSS3',
        'SQL',
        'MongoDB',
        'MariaDB',
        'Bash'
    ];

    const experiences = [
        {
            name: 'DevOps Solution Architect',
            company: {
                name: 'Freelance',
                logo: '',
                url: ''
            },
            years: '09/2021 - till now',
            notes: [
                ''
            ],
            hashtags: []

        },
        {
            name: 'Full stack developer',
            company: {
                name: 'Wingbot.ai s.r.o.',
                logo: '',
                url: ''
            },
            years: '09/2019 - 09/2021',
            notes: [
                'ChatBot & internal systems development'
            ],
            hashtags: []

        },
        {
            name: 'Sabbatical',
            company: {
                name: '',
                logo: '',
                url: ''
            },
            years: '08/2018 - 08/2019',
            notes: [
                'A year of traveling as a solo backpacker in Southeast Asia'
            ],
            hashtags: []

        },
        {
            name: 'Dev/Ops admin',
            company: {
                name: 'Storyous.com s.r.o',
                logo: '',
                url: ''
            },
            years: '02/2015 - 07/2018',
            notes: [
                'Creating and maintaining infrastructure for cloud base point of sale system'
            ],
            hashtags: ['k8s']

        },
        {
            name: 'Linux admin',
            company: {
                name: 'DHL Information Services',
                logo: '',
                url: ''
            },
            years: '09/2014 - 02/2015',
            notes: [
                '2nd level Linux support for EMEA'
            ],
            hashtags: []

        },
        {
            name: 'Unix admin',
            company: {
                name: 'DHL Information Services',
                logo: '',
                url: ''
            },
            years: '01/2013 – 08/2014',
            notes: [
                'Troubleshooting and maintaining Unix and Linux servers'
            ],
            hashtags: []

        },
        {
            name: 'Dev/Ops admin',
            company: {
                name: 'Glogster EDU',
                logo: '',
                url: ''
            },
            years: '09/2012 – 01/2013',
            notes: [
                'Maintaining infrastructure for global education startup'
            ],
            hashtags: []

        },
        {
            name: 'Network admin',
            company: {
                name: 'Casablanca INT',
                logo: '',
                url: ''
            },
            years: '04/2012 - 08/2012',
            notes: [
                'Support for server housing, VoIP, VPN and Mikrotik'
            ],
            hashtags: []

        },
        {
            name: 'Editor',
            company: {
                name: 'Internet Info s.r.o.',
                logo: '',
                url: ''
            },
            years: '02/2012 - 04/2012',
            notes: ['Writing about Linux and open source for root.cz'],
            hashtags: []

        },
        {
            name: 'Dev/Ops admin',
            company: {
                name: 'Mindsystem',
                logo: '',
                url: ''
            },
            years: '11/2009 - 02/2012',
            notes: [
                'Managing infrastructure for web hosting',
                'Managing LAMP, Posix and office servers'
            ],
            hashtags: []
        }
    ];

    return (
        <div className="experiences-container">
            <div className="column is-12">
                <div className="box skill">
                    <h2>Skills</h2>
                    <div className="skills-container">
                        {skills.map((skill) => (
                            <div className="context">
                                {`#${skill}`}
                                &nbsp;
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {experiences.map((work) => (
                <div className="column is-5">
                    <div className="box experience">
                        <h2>{work.name}</h2>
                        <b>{work.company.name}</b>
                        <p>{work.years}</p>
                        {work.notes.map((note) => (
                            <>
                                <i>{note}</i>
                                <br />
                            </>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
