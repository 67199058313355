import React from 'react';
import Layout from '../components/Layout';
import Experiences from '../components/Experiences';
import WorkBio from '../components/WorkBio.mdx';

export default () => (
    <Layout>
        <div className="columns">
            <div className="column is-half">
                <WorkBio />
            </div>
            <div className="column is-half">
                <div className="columns">
                    <Experiences />
                </div>
            </div>
        </div>
    </Layout>
);
