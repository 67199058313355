import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Skills and Experience`}</h1>
    <h2>{`💻 Experience`}</h2>
    <p>{`I have been working in IT since 2009 on various positions like Linux administration, DevOps and development.`}</p>
    <h3>{`⏲ Right now`}</h3>
    <p>{`I am working as a freelance DevOps Solution Architect.`}</p>
    <p>{`My main focus is managing infrastructure on clouds and/or bare metal.`}</p>
    <p>{`I am also able to help development teams turn in to Dev/Ops and be able to handle managing infrastructure themself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      